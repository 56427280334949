import './bootstrap'
window.$ = window.jQuery = require('jquery');

$(function(){
	$(window).scroll(function() {
		console.debug('scroll')
		if ($(document).scrollTop() > 50) {
			$('nav').addClass('shrink');
		} else {
			$('nav').removeClass('shrink');
		}
	});
})